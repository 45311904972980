<!-- 修改支付密码 -->
<template>
  <div class="UserLoginPassword">
    <div class="body-box">
      <div class="my-box">
        <div class="title-box">
          <p v-text="$t('changepassword.pay')">修改支付密码</p>
        </div>
        <div class="money-text">
          <div class="num-box">
            <h1 class="h1" v-text="$t('changepassword.verification')">验证账户</h1>
            <div class="phone-proving">
              <div
                class="click"
                v-if="userinfo && userinfo.mobile"
                @click.stop="auth_type='mobile'"
              >
                <img src="../../assets/icon/yes-dot.png" v-if="auth_type=='mobile'" />
                <img src="../../assets/icon/no-dot.png" v-else />
                <div>
                  <p v-text="$t('changepassword.phone_verification')">手机验证</p>
                  <h2 v-text="userinfo.mobile"></h2>
                </div>
              </div>
              <div class="click" v-if="userinfo && userinfo.email" @click.stop="auth_type='email'">
                <img src="../../assets/icon/yes-dot.png" v-if="auth_type=='email'" />
                <img src="../../assets/icon/no-dot.png" v-else />
                <div>
                  <p v-text="$t('changepassword.mailbox')">邮箱验证</p>
                  <h2 v-text="userinfo.email"></h2>
                </div>
              </div>
            </div>
            <div class="proving-num">
              <div>
                <input
                  v-for="(item,index) in inputList"
                  :key="index"
                  v-model="item.val"
                  type="text"
                  maxlength="1"
                  @keyup="nextFocus($event,index)"
                  @keydown="changeValue(index)"
                  ref="pwdInput"
                />
              </div>
              <p class="start click" @click.stop="fnGetSmsCode()" v-text="smsBtnText"></p>
            </div>
          </div>
          <!-- 验证账户或验证新密码 -->
          <div class="password-box">
            <h1 class="h1" v-text="$t('changepassword.new')">验证账户</h1>
            <div class="input-box">
              <div>
                <h2 v-text="$t('changepassword.input')">输入密码</h2>
                <el-input type="password" v-model="password" clearable maxlength="6"></el-input>
              </div>
              <div>
                <h2 v-text="$t('changepassword.confirm')">确认密码</h2>
                <el-input type="password" v-model="repassword" clearable maxlength="6"></el-input>
              </div>
            </div>
            <div class="botton">
              <el-button
                type="primary"
                v-text="$t('changepassword.botton')"
                @click.stop="setPayPwdSubmit"
              >提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserLoginPassword",
  data() {
    return {
      password: null,
      repassword: null,
      inputList: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }],
      userinfo: null,
      smsBtnText: this.$t("login.getcode"), // 获取验证码
      sendSmsIng: false,
      auth_type: "mobile",
      timer: null
    };
  },
  created() {
    var userinfo = sessionStorage.getItem("userinfo");
    userinfo = JSON.parse(userinfo);
    this.userinfo = userinfo;
  },
  methods: {
    /**
     * 设置支付密码提交
     */
    setPayPwdSubmit() {
      var self = this;
      var code = "";
      for (var i = 0; i < this.inputList.length; i++) {
        var input = this.inputList[i];
        if (!input.val)
          return this.common.showMessage(self.$t("login.code_error"), "error"); // 验证码错误
        code = code + input.val;
      }
      if (!self.password || self.password.length < 6)
        return this.common.showMessage(
          self.$t("login.please_password"),
          "error"
        ); //请输入6-16位密码
      if (self.password != self.repassword)
        return this.common.showMessage(self.$t("password.no_alike"), "error"); // 确认密码不正确
      self.common
        .httpPost("/api/user/updatePayPassword", {
          password: self.password,
          mobile_code: code,
          email_code: code,
          auth_type: self.auth_type
        })
        .then(ret => {
          self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
          if (ret.code == 1) {
            clearInterval(self.timer);
            self.inputList = [
              { val: "" },
              { val: "" },
              { val: "" },
              { val: "" }
            ];
            self.smsBtnText = this.$t("login.getcode");
            self.sendSmsIng = false;
            self.password = null;
            self.repassword = null;
          }
        });
    },
    /*对焦到下一个input框去*/
    nextFocus(el, index) {
      var dom = this.$refs.pwdInput,
        currInput = dom[index],
        nextInput = dom[index + 1],
        lastInput = dom[index - 1];
      if (el.keyCode != 8) {
        if (index < this.inputList.length - 1) {
          nextInput.focus();
        } else {
          currInput.blur();
        }
      } else {
        if (index != 0) {
          lastInput.focus();
        }
      }
    },
    changeValue(index) {
      this.inputList[index].val = "";
    },
    /** 获取验证码 */
    fnGetSmsCode() {
      var self = this;
      if (self.sendSmsIng) return false;
      if (self.auth_type == "mobile") {
        self.httpSendSms(self.userinfo.mobile).then(ret => {
          self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
          if (ret.code == 1) self.fnSmsBtnTim();
        });
      } else if (self.auth_type == "email") {
        self.httpSendEmail(self.userinfo.email).then(ret => {
          self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
          if (ret.code == 1) self.fnSmsBtnTim();
        });
      }
    },
    /**
     * 发送短信验证码
     * @param  {[type]}   mobile 手机号码
     * @param  {Function} fn     回调函数
     * @return {[type]}          [description]
     */
    httpSendSms: function(mobile) {
      return this.common.httpPost("/api/ajax/sendMobileMessage", {
        mobile: mobile
      });
    },
    httpSendEmail: function(email) {
      return this.common.httpPost("/api/ajax/sendEmailMessage", {
        email: email
      });
    },
    // 短信按钮计时
    fnSmsBtnTim: function() {
      var self = this;
      var time = 60;
      self.timer = setInterval(function() {
        if (time > 0) {
          self.sendSmsIng = true;
          self.smsBtnText = time + self.$t("changepassword.after"); //s后重新发送
          time--;
        } else {
          self.smsBtnText = self.$t("login.getcode");
          self.sendSmsIng = false;
          clearInterval(self.timer);
        }
      }, 1000);
    }
  },
  components: {}
};
</script>
<style scoped>
.page {
  margin: 20px 83px 44px 83px;
  display: flex;
}

.left-box {
  width: 196px;
  max-height: 1000px;
  min-height: 550px;
}

.body-box {
  width: 982px;
}

.my-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 30px;
}

.title-box {
  display: flex;
  align-items: center;
  height: 50px;
  background: rgba(247, 246, 246, 1);
}

.title-box p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 50px;
  margin-left: 30px;
}

.money-text {
  height: 500px;
  padding-left: 30px;
  padding-right: 30px;
}

.num-box {
  margin-top: 35px;
  margin-bottom: 30px;
}

h1 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
}

.h1 {
  padding-bottom: 0px !important;
}

.phone-num {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

h2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.phone-num p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
  margin-left: 5px;
}

.proving-num {
  display: flex;
  align-items: center;
}

.proving-num div {
  display: flex;
  border: 1px solid rgba(230, 230, 230, 0.7);
  margin-right: 20px;
}

.proving-num div input {
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 20px;
  text-align: center;
  border-right: 1px solid rgba(230, 230, 230, 0.7);
}

.proving-num div input:last-child {
  border-right: 0;
}

.password-box {
  border-top: 1px dashed rgba(151, 151, 151, 0.7);
  padding-top: 30px;
}

.input-box {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.input-box > div {
  margin-right: 30px;
}

.input-box div div {
  width: 215px;
  height: 34px;
  /* border-radius: 4px; */
  /* border: 1px solid rgba(230, 230, 230, 1); */
  margin-top: 10px;
}

.botton {
  width: 160px;
  height: 35px;
  background: rgba(41, 127, 171, 1);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 35px;
  text-align: center;
  margin-top: 90px;
}

.botton button {
  width: 160px;
}

.phone-proving {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.phone-proving > div {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.phone-proving > div img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.phone-proving div div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
  margin-bottom: 8px;
}

.start {
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
}

.obtain {
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 16px;
}
</style>